import React, { Suspense } from 'react';
import './App.css';
import './Common.css';
import EmptyPage from 'Screens/Lost/EmptyPage';
import Cookies from 'js-cookie';
import { Provider } from 'react-redux';
import configureStore from './store';
import CircularProgress from '@mui/material/CircularProgress';
import { StylesProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Snackbar } from './Components/CommonComponents';

import { logout } from './Actions/AuthActions';
import BaseElem from './BaseElem';
import {
    SHOW_LOADING,
    MSG_SHOW_SNACKBAR,
    MSG_SET_LOADING_STYLE,
    MSG_SET_LOADING_CONT_STYLE,
    MSG_ON_PAGE_FOCUS,
} from './Constants';
import { APP_COUNTRY } from './DataConstants';
import { version } from '../package.json';
import * as amplitude from '@amplitude/analytics-browser';

const Login = React.lazy(() => import('Screens/Login/Login'));
const PasswordExpiryBanner = React.lazy(() => import('Components/PasswordExpiryBanner'));
const RedirectComponent = React.lazy(() => import('Screens/Redirect/Redirect'));
const RootDirectory = React.lazy(() => import('Screens/RootDirectory'));

const AccountDetails = React.lazy(() => import('./Screens/AccountDetails'));
const AdminSideBar = React.lazy(() => import('./Components/SideBar/AdminSideBar'));
const AdminSideBarExpander = React.lazy(() => import('./Components/SideBar/AdminSideBarExpander'));
const B2BExportPage = React.lazy(() => import('Screens/B2BExportPage'));
const CareOwnerIndex = React.lazy(() => import('Screens/CareOwner/CareOwnerIndex'));
const CPGSTStatus = React.lazy(() => import('Screens/CPGSTStatus'));
const CPWaivers = React.lazy(() => import('Screens/CPWaivers'));
const ManageCareRecipient = React.lazy(() => import('Screens/ManageCareRecipientB2C/ManageCareRecipient'));
const OfflinePaymentSettings = React.lazy(() => import('Screens/OfflinePaymentSettings'));
const PaymentsDashboard = React.lazy(() => import('Screens/PaymentsDashboard'));
const PCAppActivationDashboard = React.lazy(() => import('Screens/PCAppActivationDashboard'));
const PricingEngine = React.lazy(() => import('Screens/Pricing/PricingEngineIndex'));
const PriorityInbox = React.lazy(() => import('Screens/PriorityInbox'));
const STCJobs = React.lazy(() => import('Screens/ShortTermContract/STCJobs'));
const Visits = React.lazy(() => import('Screens/Visits'));

// External pages -----------
const CareProSignupForm = React.lazy(() => import('./External Pages/CareProSignupForm'));
const PaymentWebLink = React.lazy(() => import('./External Pages/PaymentWebLink'));
const PIQForm = React.lazy(() => import('./External Pages/PIQForm'));
const CareProProfile = React.lazy(() => import('./External Pages/CareProProfile'));

const store = configureStore();
const ONLINE = 'online';
const OFFLINE = 'offline';

type AppState = {
    isDisconnected: boolean;
    loading: number;
    loadingStyle?: any;
    loadingContStyle?: any;
    snackbars: any[];
};

class App extends BaseElem<any, AppState> {
    _bindConChange?: any;
    _bindOnFocus?: any;
    _bindOnBlur?: any;
    _bindVisibilityChange?: any;
    _isVisible = true;
    _lastReq = 0;

    constructor(props: any) {
        super(props);
        this.state = {
            isDisconnected: false,
            loading: 0,
            snackbars: [],
        };
        this._enableListeners(true);
        document.title = 'Smiley 2.0';
    }

    _enableListeners(isEnabled: boolean) {
        const self = this,
            win = window;
        const listener = isEnabled ? 'addEventListener' : 'removeEventListener';

        if (isEnabled && !self._bindConChange) {
            win._app = self;
            self._bindConChange = self._onConnectionChange.bind(self);
            self._bindOnFocus = self._onFocus.bind(self);
            self._bindOnBlur = self._onBlur.bind(self);
            self._bindVisibilityChange = self._onVisibilityChange.bind(self);
        }

        win[listener]('focus', self._bindOnFocus);
        win[listener]('blur', self._bindOnBlur);
        document[listener]('visibilitychange', self._bindVisibilityChange);

        win[listener](ONLINE, self._bindConChange);
        win[listener](OFFLINE, self._bindConChange);
        self.setListeners(
            isEnabled,
            SHOW_LOADING,
            MSG_SHOW_SNACKBAR,
            MSG_SET_LOADING_STYLE,
            MSG_SET_LOADING_CONT_STYLE,
        );
        if (process.env.REACT_APP_AMPLITUDE) {
            const API_KEY = process.env.REACT_APP_AMPLITUDE;
            amplitude.init(API_KEY);
        };
    }

    componentDidMount() {
        const self = this;
        self._onConnectionChange();
        self._enableListeners(true);
    }

    componentWillUnmount() {
        this._enableListeners(false);
    }

    _onFocus() {
        const self = this;
        self._isVisible = true;
        self.post(MSG_ON_PAGE_FOCUS);
    }

    _onBlur() {
        this.async(() => (this._isVisible = !document.hidden), 100);
    }

    _onVisibilityChange() {
        this[document.hidden ? '_onBlur' : '_onFocus']();
    }

    onMessage(messageName: string, payload?: any) {
        const self = this,
            state = self.state;
        if (messageName === SHOW_LOADING) {
            self.setState({ loading: state.loading + (payload || 0) });
        } else if (messageName === MSG_SET_LOADING_STYLE) {
            self.setState({ loadingStyle: payload });
        } else if (messageName === MSG_SET_LOADING_CONT_STYLE) {
            self.setState({ loadingContStyle: payload });
        } else if (payload && messageName === MSG_SHOW_SNACKBAR) {
            const snackbars = state.snackbars;
            if (snackbars.find(sb => !sb.actionTitle && sb.message === payload.message)) return;
            payload.open = true;
            snackbars.push(payload);
            self.setState({ snackbars: snackbars });

            const duration = payload.duration;
            duration && self.async(() => self._removeSnackbar(payload), duration);
        }
    }

    _removeSnackbar(snackbar: any) {
        const self = this,
            snackbars = self.state.snackbars;
        snackbar.open = false;
        self.setState({ snackbars: snackbars });
        self.async(() => self.setState({ snackbars: snackbars.filter(s => s !== snackbar) }), 500);
        return true;
    }

    _onConnectionChange() {
        this.setState({ isDisconnected: !navigator.onLine });
    }

    render() {
        const self = this,
            state = self.state;
        return (
            <StylesProvider injectFirst>
                <Provider store={store}>
                    <div className="app vertical">
                        <Router>
                            <Switch>
                                /* External landing pages (Doesn't require login) */
                                <Route exact path={'/piq/apply'}>
                                    <Suspense fallback={<div />}>
                                        <PIQForm />
                                    </Suspense>
                                </Route>
                                <Route exact path={'/public-carepro-profile'}>
                                    <Suspense fallback={<div />}>
                                        <CareProProfile />
                                    </Suspense>
                                </Route>
                                {!!(process.env.REACT_APP_CP_APPLY || !process.env.REACT_APP_DISABLE_VAL) && (
                                    <Route exact path={process.env.REACT_APP_DISABLE_VAL ? ['*'] : ['/carepro/apply']}>
                                        <Suspense fallback={<div />}>
                                            <CareProSignupForm />
                                        </Suspense>
                                    </Route>
                                )}
                                {!!(process.env.REACT_APP_PAYMENTS_DOMAIN || !process.env.REACT_APP_DISABLE_VAL) && (
                                    <Route
                                        exact
                                        path={
                                            process.env.REACT_APP_PAYMENTS_DOMAIN
                                                ? ['/:paymentId', '/:paymentId/*']
                                                : ['/weblink/:paymentId', '/weblink/:paymentId/*']
                                        }>
                                        <Suspense fallback={<div />}>
                                            <PaymentWebLink />
                                        </Suspense>
                                    </Route>
                                )}
                                /* End External landing pages */
                                <Route exact path="/lost">
                                    <NavBar />
                                    <EmptyPage />
                                </Route>
                                <Route exact path="/login">
                                    <NavBar />
                                    <Suspense fallback={<div />}>
                                        <Login></Login>
                                    </Suspense>
                                </Route>
                                <PrivateRoute exact path="/">
                                    <Suspense fallback={<div />}>
                                        <RootDirectory />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute exact path="/account-details">
                                    <Suspense fallback={<div />}>
                                        <AccountDetails />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute exact path={['/careowner', '/careowner/:id/:cr_id?']}>
                                    <Suspense fallback={<div />}>
                                        <CareOwnerIndex />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute exact path="/cr/:id/:co_id?/:service_type?">
                                    <Suspense fallback={<div />}>
                                        <Suspense fallback={<div />}>
                                            <ManageCareRecipient />
                                        </Suspense>
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute exact path="/offline-payment-settings">
                                    <Suspense fallback={<div />}>
                                        <OfflinePaymentSettings />
                                    </Suspense>
                                </PrivateRoute>
                                <Route path="/redirect">
                                    <NavBar />
                                    <Suspense fallback={<div />}>
                                        <RedirectComponent />
                                    </Suspense>
                                </Route>
                                <PrivateRoute exact path="/cp-waivers">
                                    <Suspense fallback={<div />}>
                                        <CPWaivers />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/cp-gst-status">
                                    <Suspense fallback={<div />}>
                                        <CPGSTStatus />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/stc">
                                    <Suspense fallback={<div />}>
                                        <STCJobs />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/pricing">
                                    <Suspense fallback={<div />}>
                                        <PricingEngine />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/priority-inbox">
                                    <Suspense fallback={<div />}>
                                        <PriorityInbox />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/payments-dashboard">
                                    <Suspense fallback={<div />}>
                                        <PaymentsDashboard />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/b2b/nok-activation-dashboard">
                                    <Suspense fallback={<div />}>
                                        <PCAppActivationDashboard />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/b2b/*">
                                    <Suspense fallback={<div />}>
                                        <B2BExportPage />
                                    </Suspense>
                                </PrivateRoute>
                                <PrivateRoute path="/individual_visits">
                                    <Suspense fallback={<div />}>
                                        <Visits />
                                    </Suspense>
                                </PrivateRoute>
                                <Redirect from="/" to="/login" />
                            </Switch>
                        </Router>

                        <div
                            className={'loading-spinner' + (state.loading <= 0 ? ' gone' : '')}
                            style={state.loadingStyle}>
                            <div className="app-spinner-cont" style={state.loadingContStyle}>
                                <CircularProgress color="primary" />
                            </div>
                        </div>

                        <div className="app-snackbars">
                            <Snackbar
                                noClose
                                variant="info"
                                isOpen={state.isDisconnected}
                                message="No Internet Connection"
                            />

                            {state.snackbars.map(sb => {
                                return (
                                    <Snackbar
                                        key={sb.key}
                                        message={sb.message}
                                        variant={sb.variant}
                                        actionTitle={sb.actionTitle}
                                        isOpen={sb.open}
                                        noClose={!!sb.duration}
                                        onAction={sb.onAction}
                                        onClose={() => self._removeSnackbar(sb)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Provider>
            </StylesProvider>
        );
    }
}

function PrivateRoute({ children, ...props }: any) {
    return (
        <Route
            {...props}
            render={({ location }) =>
                Cookies.get('userid') ? (
                    <Suspense fallback={<div />}>
                        <PasswordExpiryBanner />
                        <AdminNavBar />
                        <div className="app-section">
                            <Suspense fallback={<div />}>
                                <AdminSideBar />
                            </Suspense>
                            <div id="right" className="content-section">
                                <div className="main-container">{children}</div>
                            </div>
                        </div>
                    </Suspense>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

async function logOut() {
    let loggedOut: boolean = await logout();
    if (loggedOut) {
        Cookies.remove('userid');
        window.location.reload();
    }
}

function displayVersionAndCountryAndEnvironment() {
    let versionString = '';
    const isDev = process?.env?.REACT_APP_API_URL?.includes('dev');
    const isStaging = process?.env?.REACT_APP_API_URL?.includes('testing');
    let envString = isDev ? 'dev' : '';
    envString = isStaging ? 'staging' : envString;

    if (isDev || isStaging) {
        versionString = ` ${version}-${envString}-${APP_COUNTRY}`;
    }
    return versionString;
}

function NavBar() {
    return (
        <div className="navigation-bar h center">
            <img
                className="homage-logo"
                src="/homage_logo_white.png"
                alt=""
                onClick={() => {
                    logOut();
                }}
            />
            <span className="homage-title">HOMAGE{displayVersionAndCountryAndEnvironment()}</span>
        </div>
    );
}

function AdminNavBar() {
    return (
        <div className="navigation-bar h center">
            {Cookies.get('userid') ? (
                <Suspense fallback={<div />}>
                    <AdminSideBarExpander />
                </Suspense>
            ) : (
                ''
            )}
            <a className="h vc" href="/">
                <img className="homage-logo" src="/homage_logo_white.png" alt="" />
                <span className="homage-title">HOMAGE{displayVersionAndCountryAndEnvironment()}</span>
            </a>
            <div
                className="logout"
                onClick={() => {
                    logOut();
                }}>
                Logout
            </div>
        </div>
    );
}

export default App;
