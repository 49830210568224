import {createContext} from 'react';
import {getShortMonths, getShortWeekDays, isPastDate} from './DateUtils';
import {UNDEF} from '../Constants';
import App from '../App';

declare global {
    interface Window {
        _app: App;
        _listeners: {};
        _hack: any;
    }
}

export function setDocumentScrollEnabled(enabled: boolean) {
    const doc = document.body.parentElement as any;
    if (doc.scrollEnabled !== enabled) {
        doc.scrollEnabled = enabled;

        doc.style = enabled ? '' : 'padding-right: 8px; overflow: hidden;';
        //doc.style.overflow = enabled ? '' : 'hidden';
        //doc.style.paddingRight = enabled ? '' : '8px';

        //doc.style.transition = '4s ease-out'
        return true;
    }
    return false;
}

// clamp a value between min max
export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

export function getCenterOf(element: HTMLElement | any) {
    const rect: any = element.getBoundingClientRect();
    return {x: rect.x + rect.width / 2, y: rect.y + rect.height / 2};
}
export function getTopLeftOf(element: HTMLElement | any) {
    const rect: any = element.getBoundingClientRect();
    return {x: rect.x, y: rect.y};
}
export function getTopRightOf(element: HTMLElement | any) {
    const rect: any = element.getBoundingClientRect();
    return {x: window.innerWidth - rect.x - rect.width, y: rect.y};
}

export function twoDecimal(num: any) {
    return Math.round(parseFloat(num) * 100) / 100;
}

/**
 * Position an element in global space according to anchor position
 *
 * @param elem Element to position
 * @param anchor boundingClientRect of anchor element
 */
export function positionVerticallyWithAnchor(
    elem: HTMLElement,
    anchor: any,
    xOffset = 0,
    yOffset = 0,
    alignWithTop = false,
) {
    const style = elem.style as any;
    const anchorY = anchor.y;
    const anchorHeight = alignWithTop ? 0 : anchor.height;
    const elemWidth = elem.offsetWidth;
    const elemHeight = elem.offsetHeight;
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

    const topDiff = anchorY - yOffset - elemHeight;
    const bottomDiff = anchorY + anchorHeight + yOffset + elemHeight - winHeight;

    let alignBottom = true;

    // can't fit on top or bottom
    if (topDiff < 0 && bottomDiff > 0) {
        // pick the best fit
        alignBottom = bottomDiff > -1 * topDiff;
        //TODO: shrink elem height
    }
    // can fit below the anchor
    else if (bottomDiff < 0) {
        alignBottom = true;
    }

    // can fit on top
    else if (topDiff > 0) {
        alignBottom = false;
    }

    if (alignBottom) {
        style.bottom = '';
        style.top = twoDecimal(((anchorY + anchorHeight + yOffset) * 100) / winHeight) + '%';
    } else {
        style.top = '';
        let postionFromBottom = winHeight - anchorY + yOffset;
        if (alignWithTop) postionFromBottom -= anchor.height;

        style.bottom = twoDecimal((postionFromBottom * 100) / winHeight) + '%';
    }

    style.left = twoDecimal((clamp(anchor.x + xOffset, 10, winWidth - elemWidth - 10) * 100) / winWidth) + '%';
    style.opacity = 1;
    style.transform = 'unset';
}

// Get minutes value from raw string
// inputs: 2, 230, 2:30, 2:15pm, 230a, 1800 etc...
// outputs correct time values in minutes: 2am, 2:30am, 2:30am, 2:15pm, 2:30am, 6pm
export function guessMinutes(str: string): number | undefined {
    const tokens = str.split(new RegExp('(,|\\.| |:|am|pm|a|p)'));
    let numbers: number[] = [],
        isam,
        ispm;

    tokens.forEach(token => {
        const lcaseTkn = token.toLowerCase();

        if (lcaseTkn === 'pm' || lcaseTkn === 'p') {
            ispm = true;
        } else if (lcaseTkn === 'am' || lcaseTkn === 'a') {
            isam = true;
        } else {
            let val = parseInt(token, 10);
            if (!isNaN(val)) {
                // match formats 230, 2300
                let strVal = String(val),
                    len = strVal.length;
                if (!numbers.length && len > 2) {
                    const minStart = len === 3 ? 1 : 2;
                    numbers.push(parseInt(strVal.substr(0, minStart), 10));
                    numbers.push(parseInt(strVal.substr(minStart), 10));
                } else {
                    numbers.push(val);
                }
            }
        }
    });

    const len = numbers.length;

    if (len >= 1) {
        let hours = numbers[0];
        let minutes = (numbers[1] || 0) % 60;
        if (hours > 12) {
            // military time?
            return (hours % 24) * 60 + minutes;
        } else {
            // look for am pm
            if (isam) hours = hours % 12;
            else if (ispm) hours = (hours % 12) + 12;

            return hours * 60 + minutes;
        }
    }
}

// generate Date Object from raw string
// inputs: sat, 2, 2oct, 10nov2019, 10-nov-2019
// output: valid date object or undefined
export function guessDate(str: string, currentDate?: Date): Date | undefined {
    const months = getShortMonths();
    const weekdays = getShortWeekDays().map(wn => wn.toLowerCase());
    const matches = months.concat(weekdays).join('|').toLowerCase();

    const monthsMap = months.reduce((map: any, obj: string, idx: number) => {
        map[obj.toLowerCase()] = idx + 1;
        return map;
    }, {});

    const tokens = str.toLowerCase().split(new RegExp(`(,|\\.| |-|:|${matches})`));

    let day = -1,
        date = 0,
        month = 0,
        year = 0;

    for (var i = 0; i < tokens.length; i++) {
        const token = tokens[i];

        const weekIdx = day === -1 ? weekdays.indexOf(token) : -1;
        if (weekIdx !== -1) {
            day = weekIdx;
            continue;
        }

        // try to find month
        const monthIndex = monthsMap[token] || 0;
        if (!month && monthIndex > 0) {
            month = monthIndex;
            continue;
        }

        let val = parseInt(token, 10);
        if (isNaN(val) || !val) continue;

        if (!date && val <= 31) {
            date = val;
            continue;
        }

        if (!year && val > 1900 && val < 2219) {
            year = val;
        }
    }

    if (!date && !month && !year) return;

    const newDate = new Date(currentDate || new Date());
    year && newDate.setFullYear(year);
    month && newDate.setMonth(month - 1); //months starts from 0
    date && newDate.setDate(date);

    if (!year) {
        year = new Date().getFullYear();
        newDate.setFullYear(year);
        isPastDate(newDate) && newDate.setFullYear(year + 1);
    }

    return newDate;
}

export function blur(e: any) {
    e && e.currentTarget && e.currentTarget.blur();
    return true;
}

// only blur when left mouse click
export function cblur(e: any) {
    e && e.detail === 1 && e.currentTarget && e.currentTarget.blur();
    return true;
}

export function eatClick(e: any) {
    e.stopPropagation();
    e.preventDefault();
    return true;
}
export function stopPropagation(e: any) {
    e.stopPropagation();
    return true;
}

export function validatePhoneNumber(phone: string) {
    const plus = phone[0] === '+' ? '+' : '';
    return plus + phone.replace(/[^0123456789]/g, '');
}

export function LOG(...msg: any): boolean {
    return true;
}

/**
 * Capitalise the first letter of string
 * @param str "night rate"
 * @returns "Night rate"
 */
export function capitalize(str: string) {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Capitalise the first letter of every word in a string
 * @param str "night rate"
 * @returns "Night Rate"
 */
export function capitalizeAll(str: string) {
    return (
        str &&
        str
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
            .join(' ')
    );
}

/**
 * Throttle multiple calls to be executed only once within the given `wait` time
 * @param func Function to be executed
 * @param wait Time in milliseconds
 * @param immediate pass `true` To call the function immediately
 */
export function debounce(caller: any, func: Function, wait: number, immediate?: boolean) {
    const self = caller;
    const timeout = self._debounceTimeout;
    let args = arguments;

    const later = function () {
        self._debounceTimeout = 0;
        if (!immediate) func.apply(self, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    self._debounceTimeout = window.setTimeout(later, wait);
    if (callNow) func.apply(self, args);
}

export function validateEmail(email?: string) {
    if (!email) return false;
    var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Remove weird unicode character from text that's breaking the mobile apps
export function cleanTextForMobileCompatibility(text?: String | undefined) {
    return !text ? text : text.replace(/\u2028/g, '');
}

// check for empty/non-existent string or array
export function isBlank(v: any) {
    return !v || !v[0] || (v.trim && !v.trim());
}

export function isObjEmpty(obj: any) {
    if (!obj) return true;
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
}

export function isEmpty(value: any) {
    return (
        value === undefined ||
        value === null ||
        value === '' ||
        (typeof value === 'string' && value.trim() === '') ||
        (value instanceof Array && !value.length)
    );
}

export function fontsLoaded() {
    return (document as any).fonts.status === 'loaded';
}

// Verify phone number
// SG 8 digits, MY (between 9-10)
// return undefined for success, error object otherwise
export function validatePhone(isMandatory: boolean, phone?: string) {
    const digits = (phone || '').length;
    if (
        (phone && phone.indexOf('+65') === 0 && digits > 0 && digits !== 11) ||
        (phone && phone.indexOf('+60') === 0 && digits > 0 && (digits < 12 || digits > 13))
    ) {
        return {success: false, error: 'Invalid phone number'};
    }
    if (isMandatory && isBlank(phone)) {
        return {success: false};
    }
    return {success: true};
}

// Verify phone number
// SG 8 digits, MY (between 9-10)
export function validateCoPhone(phone?: string) {
    const digits = (phone || '').replace('-', '').length; //to remove '-' from number
    if (
        (phone && phone.indexOf('+65') === 0 && digits > 0 && digits !== 11) ||
        (phone && phone.indexOf('+61') === 0 && digits > 0 && (digits < 12 || digits > 13)) ||
        (phone && phone.indexOf('+60') === 0 && digits > 0 && (digits < 12 || digits > 13))
    ) {
        return {success: false, error: 'Invalid phone number'};
    }
    if (isBlank(phone)) {
        return {success: true};
    }
    return {success: true};
}

// elem should be direct child of scroll container
//  or none of the parents have relative position
export function scrollToElem(elem: any, scrollContainer: any, offset = 20) {
    try {
        if (!elem) return;
        const scrollview = scrollContainer || document.body;
        const bodyRect = scrollview.getBoundingClientRect().top;
        const elementRect = elem.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect + scrollview.scrollTop;
        const offsetPosition = elementPosition - offset;

        (scrollContainer || window).scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });

        //elem.scrollIntoView();
        //window.scrollBy(0, -80);
    } catch (e) {}
}
export function scrollToOffset(position: number, scrollContainer: any, offset = 20) {
    try {
        (scrollContainer || window).scrollTo({
            top: position - offset,
            behavior: 'smooth',
        });
    } catch (e) {}
}
export function focusElem(elemId: string, delay = 10) {
    setTimeout(() => {
        const elem = document.getElementById(elemId);
        elem && elem.focus();
    }, delay);
}

export function getCardIcon(cardType?: string) {
    const amex = 'american-express-straight-64px.png';
    const mastercard = 'mastercard-straight-64px.png';
    const visa = 'visa-straight-64px.png';
    const unknown = 'card-straight-64px.png';

    const types: any = {
        'American Express': amex,
        amex,
        MasterCard: mastercard,
        mastercard,
        Visa: visa,
        visa,
        Unknown: unknown,
        unknown,
    };
    if (cardType && types[cardType]) {
        return 'https://homage-cdn.s3-ap-southeast-1.amazonaws.com/mobile-icons/payment/types/' + types[cardType];
    }
    return '/self-serve/card@3x.png';
}

export function stripCurrency(str: any) {
    return ((str || '') + '').replace(/(\$|SGD|RM|MYR|A\$|AUD|\(|\)|\+| |)/g, '');
}

export function getCurrencySymbol() {
    const map: any = {
        SGD: '$',
        MYR: 'RM',
        AUD: 'A$',
    };
    return map[getCurrencyCode()];
}
export function getCurrencyCode() {
    return process.env.REACT_APP_CURRENCY || 'SGD';
}

///////////////// OBSERVER PATTERN /////////////////
/**
 * Post a message with optional data
 * @param messageName
 * @param payload
 */
export function postMessage(messageName: string, payload?: any, delay?: number) {
    if (delay !== UNDEF) {
        setTimeout(() => postMessage(messageName, payload), delay);
        return;
    }

    const allListeners: any = window._listeners || {};
    window._listeners = allListeners;
    const msgListeners: any[] = allListeners[messageName] || [];
    for (let k = msgListeners.length; k--; ) {
        msgListeners[k].onMessage(messageName, payload);
    }
}

/**
 * Add/Remove listeners for specific messages
 * @param messageNames array of messageNames to listen to
 */
export function setMessageListeners(caller: any, enable: boolean, ...messageNames: any[]) {
    const allListeners: any = window._listeners || {};
    window._listeners = allListeners;

    for (let k = messageNames.length; k--; ) {
        const messageName = messageNames[k];
        let msgListeners = allListeners[messageName] || [];

        enable && msgListeners.indexOf(caller) === -1 && msgListeners.push(caller);
        !enable && (msgListeners = msgListeners.filter((l: any) => l !== caller));

        allListeners[messageName] = msgListeners;
    }
}
///////////////// END OBSERVER PATTERN /////////////////

/**
 * Store to local storage
 * @param key: String key
 * @param value: Object/String, if missing it will remove the key from local storage
 * @param stringify: whether to stringify the value before saving
 */
export function localStorageSave(key: string, value: any = UNDEF, stringify = true) {
    const v = stringify ? JSON.stringify(value) : value;
    try {
        if (value === null || value === UNDEF) window.localStorage.removeItem(key);
        else window.localStorage.setItem(key, /** @type {string} */ v);
    } catch (ex) {}
}

/**
 * Get a value from local storage
 * @param key: String local storage name
 * @param unstringify: if true it'll transform the value using JSON.parse
 * @return object from localstorage or null
 */
export function localStorageGet(key: string, unstringify = true): any {
    try {
        const value = window.localStorage.getItem(key);
        return unstringify && value ? JSON.parse(value) : value;
    } catch (ex) {}
}

export function loadScript(url: any, callback: any) {
    let script: any = document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState) {
        //IE
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        //Others
        script.onload = callback;
    }
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
}

// Resize an image to width = 300px with proper aspect ratio
export function resizeImage(imageData: any, onResize: (data: any) => void, imageSize = 300) {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const error = () => {
        onResize(UNDEF);
        return false;
    };

    if (!ctx) return error();

    img.onload = function () {
        const image = this as any;
        const imgWidth = image.naturalWidth;
        const imgHeight = image.naturalHeight;
        const cw = Math.min(imageSize, imgWidth);
        const ch = imgHeight / (imgWidth / cw);

        canvas.width = cw;
        canvas.height = ch;

        ctx.drawImage(image, 0, 0, imgWidth, imgHeight, 0, 0, cw, ch);
        canvas.toBlob(blob => onResize(blob), 'image/jpeg', 0.9);
    };

    img.onerror = error;
    img.src = 'data:image/jpeg;base64,' + btoa(imageData);
}

/**
 * helper function to set/remove attribute
 */
export function _attr(shouldSetAttrib: any) {
    return shouldSetAttrib ? '' : UNDEF;
}
export const clean = (obj: any) => {
    for (var propName in obj) {
        if (!obj[propName] || (typeof obj[propName] === 'object' && obj[propName].length === 0)) {
            delete obj[propName];
        }
    }
};

export const removeNullProperties = (obj: any) => {
    for (var propName in obj) {
        if (obj[propName] === null) {
            delete obj[propName];
        }
    }
    return obj;
};

// splits a string by commas and trims off spaces
export const splitString = (string: string) => {
    return string.split(",").map((item) => item.trim());
};

export const varToString = (varObj: object): string => Object.keys(varObj)[0];

export function getName(salutation?: string, first_name?: string, last_name?: string) {
    const name = [salutation, first_name, last_name].filter(s => !isBlank(s)).join(' ');
    return name;
}
export function getCOName(recipientStore: any) {
    const details = recipientStore && recipientStore.careRecipient;
    const cr = details && details.cr;
    const co = details && details.co;
    if (cr) {
        return cr.vwo && cr.vwo[0] ? cr.vwo_name : getName(co.co_salutation, co.co_first_name, co.co_last_name);
    }
    return '';
}
export const getEllipsisText = (text: string, length: number) =>
    text.length > length ? text.slice(0, length) + '...' : text;
export const paginate = (data: any, currentPage: number, pageSize: number) => {
    const startIndex = pageSize * (currentPage - 1);
    const endIndex = currentPage * pageSize;
    return data?.slice(startIndex, endIndex);
};
export const setStateCallback = (property: string, value: any) => (prevState: any) => ({
    ...prevState,
    [property]: value,
});

export const PIQContext = createContext({});

// get chunk from an array
export const chunk = (arr: any[], chunkSize = 1, cache: any[]) => {
    const arrCopy = [...arr];

    if (chunkSize <= 0) return cache;
    while (arrCopy.length) cache.push(arrCopy.splice(0, chunkSize));
    return cache;
};

export const generateLinkForVAL = (url: string, isB2C: boolean) => {
    const isStaging = url.includes('testing');
    const domainParts = new URL(url).hostname.split('.');
    let firstDomainPart;
    let updatedDomain;
    let updatedURL;

    if (isStaging) {
        firstDomainPart = isB2C ? `${domainParts[0]}-v2` : `${domainParts[0]}-b2b`;
    } else {
        firstDomainPart = isB2C ? `${domainParts[0]}2` : `${domainParts[0]}2-b2b`;
    }

    domainParts[0] = firstDomainPart;
    updatedDomain = domainParts.join('.');
    updatedURL = `https://${updatedDomain}`;
    return updatedURL;
};
