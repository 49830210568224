import { UNDEF } from "../Constants";
import { APP_COUNTRY } from "../DataConstants";

export const TIMEZONES: { [string: string]: string } = {
  Singapore: "Asia/Singapore", // GMT+8
  Malaysia: "Asia/Kuala_Lumpur", // GMT+8
  Australia: "Australia/Melbourne", // GMT+11
};
export const TIMEZONES_VALUE: { [string: string]: string } = {
  Singapore: "GMT+08",
  Malaysia: "GMT+08",
  Australia: "GMT+11",
};

export function getMonths(): string[] {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}

export function getShortMonths(): string[] {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
}

export function getDayNames(offset = 0): string[] {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days.map((_: any, i: number) => days[(i + offset) % 7]);
}

// 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
export function getShortWeekDays(offset = 0): string[] {
  return getDayNames(offset).map((d: string) => d.substr(0, 3));
}

// 'S', 'M', 'T', 'W', 'T', 'F', 'S'
export function getMiniWeekDays(offset = 0): string[] {
  return getDayNames(offset).map((d: string) => d[0]);
}

// week start from Monday
export function firstDayOfWeek(): number {
  return 1;
}

// convert military time to am pm format
export function hoursToAMPM(militaryHours: number): string {
  const ampm = militaryHours >= 12 && militaryHours < 24 ? "PM" : "AM";
  const t = militaryHours % 12 || 12;
  return t + " " + ampm;
}

// Convert minutes to AM PM format
// Exmaple input: 780  output: 1pm  compactFormat=false 01:00pm
// Exmaple input: 810  output: 1:30pm  compactFormat=false 01:30pm
export function minsToAMPM(
  minutes: number,
  compactFormat = true,
  capitalise = false,
  separator = ""
): string {
  const mins = minutes % 1440; // 1440 (Need within 24 hours)
  const militaryHours = Math.floor(mins / 60);

  let ampm =
    separator + (militaryHours >= 12 && militaryHours < 24 ? "pm" : "am");
  capitalise && (ampm = ampm.toUpperCase());
  const h = militaryHours % 12 || 12;
  const m = mins - militaryHours * 60;

  if (compactFormat) {
    let min = m ? ":" + m : "";
    return `${h}${min}${ampm}`;
  } else {
    let hr = h < 10 ? "0" + h : String(h);
    let min = ":" + (m < 10 ? "0" + m : m);
    return `${hr}${min}${ampm}`;
  }
}

// Convert minutes to AM PM format
// Exmaple input: 780  output: 1pm  compactFormat=false 01:00 pm
// Exmaple input: 810  output: 1:30pm  compactFormat=false 01:30 pm
export function dateToAMPM(
  date?: Date,
  compactFormat = true,
  offsetTimeZone = false
): string {
  if (!date) return "";
  //TODO: fix later when timezone changes are implemented
  /*const d = offsetTimeZone
    ? new Date(date.getTime() + destOffsetInMillis())
    : date;*/

  const d = date;
  return minsToAMPM(d.getHours() * 60 + d.getMinutes(), compactFormat);
}

// Convert minutes to Military format
// Exmaple input: 780  output: 13:00
export function minsToMilitary(minutes: number) {
  const mins = minutes % 1440; // 1440 (Need within 24 hours)
  const h = Math.floor(mins / 60);
  const m = mins - h * 60;
  return (h < 10 ? "0" + h : String(h)) + ":" + (m < 10 ? "0" + m : String(m));
}

// Convert a date to different timezone
// https://stackoverflow.com/a/54127122/1158325
export function convertTZ(date: Date, tzString: string) {
  // console.log(
  //   "🚀 ~ file: DateUtils.tsx ~ line 142 ~ convertTZ ~ tzString",
  //   tzString
  // );
  // console.log("🚀 ~ file: DateUtils.tsx ~ line 142 ~ convertTZ ~ date", date);
  return new Date(
    date.toLocaleString("en-US", {
      timeZone: tzString ?? "Australia/Melbourne",
    })
  );
}

/**
 * Convert local date timezone to CR's timezone
 * @param date in local timezone
 */
export function convertToDestTZ(date: Date | undefined, timezone = "") {
  if (!date) return;
  const destTimeZone = timezone || TIMEZONES[APP_COUNTRY];
  return convertTZ(date, destTimeZone);
}

/**
 * return offset in milliseconds with destination timezone (CR's timezone)
 */
export function destOffsetInMillis(timezone = "", date = new Date()): number {
  const curTZ = date;
  curTZ.setHours(0, 0, 0, 0);
  const destTZ = convertToDestTZ(curTZ, timezone) || curTZ;
  return destTZ.getTime() - curTZ.getTime();
}

// Convert javascript date to DD/MM/YYYY format
export function dateDobFormat(d?: Date | string): string | undefined | Date {
  if (!d) return UNDEF;
  if (typeof d === "string") return d.split("/").reverse().join("/");
  let year = d.getFullYear().toString();
  let month = (d.getMonth() + 101).toString().slice(-2);
  let date = (d.getDate() + 100).toString().slice(-2);
  return date + "/" + month + "/" + year;
}

export const convertToCrTimezone = (date: string, timezone: string) => {
  const newdate = new Date(
    new Date(date).getTime() - destOffsetInMillis(timezone, new Date(date))
  ).toISOString();
  return newdate;
};
// Convert javascript date to YYYY-MM-DD format
export function dateFormat(d?: Date): string | undefined {
  if (!d) return UNDEF;
  let year = d.getFullYear().toString();
  let month = (d.getMonth() + 101).toString().slice(-2);
  let date = (d.getDate() + 100).toString().slice(-2);
  return year + "-" + month + "-" + date;
}

export function dateToDDMMYYYY(d?: Date): string | undefined {
  const s = dateFormat(d);
  if (s) {
    return s.split("-").reverse().join("/");
  }
  return s;
}

// create date object by taking care of timezone offset input format: YYYY-MM-DD
export function toDate(d?: string): Date | undefined {
  try {
    if (!d) return UNDEF;
    return new Date(
      new Date(d).getTime() + new Date(d).getTimezoneOffset() * 60 * 1000
    );
  } catch (e) {
    return UNDEF;
  }
}

//YYYY-MM-DD to Date object
export function toYYYYMMDD(dateStr?: string) {
  if (!dateStr) return UNDEF;
  try {
    const [year, month, day] = dateStr.split("-");
    if (!year || month === UNDEF || day === UNDEF) return UNDEF;
    const ret = new Date();
    ret.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
    return ret;
  } catch (e) {
    return UNDEF;
  }
}

export function dateFromDDMMYYYY(dateStr?: string) {
  if (!dateStr) return UNDEF;
  try {
    const [day, month, year] = dateStr.split("/");
    if (year === UNDEF || month === UNDEF || day === UNDEF) return UNDEF;
    const ret = new Date();
    ret.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
    return ret;
  } catch (e) {
    return UNDEF;
  }
}

// Parse date & time string i.e: 2019-09-27T18:00, 2019-11-30T21:00:00.000Z
export function parseDate(dateString: string, offsetTimezone = false): Date {
  //if((dateString as any).getDate) return dateString as any;
  //const parts = dateString.split(new RegExp(/[-T:]+/)).map(v => parseInt(v));
  //const date = new Date(parts[0], parts[1]-1, parts[2], parts[3], parts[4], 0);
  //return date;
  const date = new Date(dateString);
  //TODO: fix later when timezone changes are implemented
  //if (offsetTimezone) {
  //  return new Date(date.getTime() + destOffsetInMillis());
  //} else {
  return date;
  //}
}
//TODO: fix later when timezone changes are implemented
// Return new date with destination timezone offset
export function newDate() {
  return new Date();
  //return new Date(new Date().getTime() + destOffsetInMillis());
}

/**
 * Add/Subtract days from date
 */
export function dateAdd(date: any, daysToAdd: number): Date | null {
  if (!date) return null;
  const newDate = new Date(date);
  daysToAdd && newDate.setDate(date.getDate() + daysToAdd);
  return newDate;
}

// return true if date is in the past or null/undefined date
export function isPastDate(
  date?: Date | null,
  compareTimeAlso = false
): boolean {
  const today = convertToDestTZ(new Date()) ?? new Date();
  !compareTimeAlso && today.setHours(0, 0, 0, 0);
  return !date || date.getTime() < today.getTime();
}

// return true if date is in the future
export function isFutureDate(date: Date): boolean {
  const today = new Date();
  return date && date.getTime() > today.getTime();
}

// return true if dates are same ignoring time part
export function areDatesSame(
  d1?: Date,
  d2?: Date,
  compareTimeAlso = false
): boolean {
  if (d1 === d2) return true;
  if (!d1 || !d2) return false;

  let ret =
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear();

  if (ret && compareTimeAlso) {
    ret =
      d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes();
  }
  return ret;
}

export function calculateDurationHourMinute(timeStart: Date, timeEnd: Date) {
  const msDiff = timeEnd.getTime() - timeStart.getTime();
  const min = msDiff / 60000;
  const hour = Math.floor(msDiff / 3600000);
  const remainderMin = Math.floor(min - 60 * hour);
  return {
    hours: hour,
    minutes: remainderMin,
  };
}

// Return formatted duration between two dates
//  long forms: 2 hours, 1 hour 30 minutes, 20 hours 15 minutes
//  short forms: 2h, 1.5h, 20h 15m
export function getDurationString(
  timeStart: Date,
  timeEnd: Date,
  shortForm = false
): string {
  if (!timeStart || !timeEnd || timeEnd.getTime() < timeStart.getTime())
    return "";
  const { hours, minutes } = calculateDurationHourMinute(timeStart, timeEnd);

  let ret = shortForm
    ? `${hours}${
        minutes === 30 ? ".5h" : minutes > 0 ? "h " + minutes + "m" : "h"
      }`
    : `${hours} hour${hours > 1 ? "s" : ""}${
        minutes > 0 ? " " + minutes + " minutes" : ""
      }`;

  if (ret.startsWith("0h ")) return ret.slice(3);
  if (ret.startsWith("0hour ")) return ret.slice(6);
  return ret;
}

/**
 * Convert date to formatted string
 * @param d Should be date object or parseable date string in format '2019-09-27T18:00'
 * @returns Sun 20 Aug 2019
 */
export function formatDate(d: any, offsetTimezone = false): string {
  if (!d) return "";
  if (typeof d === "string") {
    d = parseDate(d, offsetTimezone);
  } else {
    //TODO: fix later when timezone changes are implemented
    //d = offsetTimezone ? new Date(d.getTime() + destOffsetInMillis()) : d;
  }

  const date = d.getDate(),
    month = d.getMonth(),
    year = d.getFullYear();
  const monthName = getShortMonths()[month];

  return [getShortWeekDays()[d.getDay()], date, monthName, year].join(" ");
}

// Convert date to formatted string: e.g. '20 Aug 2019'
export function formatDateDDMMMYYYY(d: any, offsetTimezone = false): string {
  if (!d) return "";
  if (typeof d === "string") {
    d = parseDate(d, offsetTimezone);
  }

  const date = d.getDate(),
    month = d.getMonth(),
    year = d.getFullYear();
  const monthName = getShortMonths()[month];

  return [date, monthName, year].join(" ");
}

/**
 * Convert date to "27 Sep"
 * @param d Should be date object or parseable date string in format '2019-09-27T18:00'
 * @returns 27 Sep
 */
export function formatDateDDMMM(d: any): string {
  const parts = formatDate(d).split(" ");
  return (parts[1] || "") + " " + (parts[2] || "");
}

// return time portion from date object i.e. "10:00AM"
export function formatTime(date: Date): string {
  return !date ? "" : dateToAMPM(date, false).toUpperCase();
}

// Get Full Month name from date (March, April etc...)
export function getMonthName(d: any): string {
  if (!d) return "";
  if (typeof d === "string") d = parseDate(d);
  return getMonths()[d.getMonth()];
}

// Get Full weekDay name from date (Sunday, Monday etc..)
export function getWeekdayName(d: any): string {
  if (!d) return "";
  if (typeof d === "string") d = parseDate(d);
  return getDayNames()[d.getDay()];
}

// returns 1,2,3,4,5
export function nthWeekdayNum(d: Date): number {
  const day = d.getDay();
  let num = 1,
    d2: any = new Date(d);
  while (d2 && d2.getDate() !== 1) {
    d2 = dateAdd(d2, -1);
    if (d2 && d2.getDay() === day) num++;
  }
  return num;
}

// examples:
//  baseDate: 30 nov 2019   d: 30 nov 2019  returns: last sat of dec, 28-dec 2019
//  baseDate: 30 nov 2019   d: 28 dec 2019  returns: last sat of jan, 25-jan 2020
//  baseDate: 14 nov 2019   d: 14 nov 2019  returns: 2nd tue of dec, 12-dec 2019
export function getSameDayNextMonth(baseDate: Date, d: Date): Date {
  let d2: any = new Date(d);
  const nthDay = nthWeekdayNum(baseDate);
  while (d2.getMonth() <= d.getMonth() && d2.getFullYear() <= d.getFullYear()) {
    d2 = dateAdd(d2, 7);
  }
  let curDay = 1;

  if (nthDay < 5) {
    while (curDay < nthDay) {
      d2 = dateAdd(d2, 7);
      curDay++;
    }
  } else {
    // next month might not have 5th tue,wed etc.
    d2 = dateAdd(d2, 7 * 3);
    const d3: any = dateAdd(d2, 7);
    d2 = d3.getMonth() === d2.getMonth() ? d3 : d2;
  }

  return d2;
}

//SO: https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number/13627586
export function nth(n: number) {
  return n + ([UNDEF, "st", "nd", "rd"][(n / 10) % 10 ^ 1 && n % 10] || "th");
}

// Returns: second Sunday, third Friday, first Sunday, last Monday etc..
export function nthWeekday(date: any): string {
  if (!date) return "";
  let d: any = typeof date === "string" ? parseDate(date) : new Date(date);

  const day = d.getDay(),
    dayName = getDayNames()[day];
  let num = 1;

  while (d.getDate() !== 1) {
    d = dateAdd(d, -1);
    if (d.getDay() === day) num++;
  }

  switch (num) {
    case 1:
      return `first ${dayName}`;
    case 2:
      return `second ${dayName}`;
    case 3:
      return `third ${dayName}`;
    case 4:
      return `fourth ${dayName}`;
    case 5:
      return `last ${dayName}`;
    default:
      return "";
  }
}

/**
 * Convert dates to formatted string
 * @param d Should be date objects or parseable date strings in format '2019-09-27T18:00'
 * @returns Aug 20-23, Aug 27-30, Sep 10, 12-14
 */
export function formatMultipleDates(dates?: any[]): string {
  if (!dates || !dates.length) return "";

  const jsDates = dates.map((d) => (typeof d === "string" ? parseDate(d) : d));
  jsDates.sort((a, b) => a.getTime() - b.getTime());

  const output: string[] = [];
  const shortMonths = getShortMonths();
  let incStart = "";

  for (let i = 0; i < jsDates.length; i++) {
    const d = jsDates[i];
    const prevDate = i > 0 && jsDates[i - 1];

    const monthName = shortMonths[d.getMonth()];
    const prevMonth = i === 0 ? "" : shortMonths[prevDate.getMonth()];
    const date = d.getDate();

    const isIncrementalDate =
      i > 0 &&
      prevDate.getDate() + 1 === date &&
      prevDate.getMonth() === d.getMonth() &&
      prevDate.getFullYear() === d.getFullYear();

    if (isIncrementalDate) {
      const lastIdx = output.length - 1;
      if (!incStart) {
        incStart = output[lastIdx];
        output[lastIdx] = incStart + "-" + date;
      } else {
        output[lastIdx] = incStart + "-" + date;
      }
    } else {
      incStart = "";
      output.push(monthName !== prevMonth ? monthName + " " + date : date);
    }
  }
  return output.join(", ");
}

// Fix start date to be multiple of 15 mins
export function fixVisitStartDate(startDate: Date) {
  const rem = startDate.getMinutes() % 15;
  const ret = new Date(startDate);
  if (rem !== 0) {
    ret.setMinutes(ret.getMinutes() - rem);
  }
  ret && ret.setSeconds(0);
  return ret;
}

// validate duration
// min 30 minutes, max 24 hours, duration should be multiple of 15 mins
export function fixVisitEndDate(sDate: Date, eDate: Date) {
  const diff = calculateDurationHourMinute(sDate, eDate);
  const mins = diff.hours * 60 + diff.minutes;
  const hours = mins / 60;
  let retDate = new Date(eDate);

  if (hours < 0.5) {
    // add 30 minutes
    retDate = new Date(sDate.getTime() + 1800000); //30 * 60 * 1000
  } else if (hours >= 24) {
    // add 24 hour
    retDate = new Date(sDate.getTime() + 86400000); //24 * 60 * 60 * 1000
  } else if (mins % 15 !== 0) {
    // minutes should be 15 minutes apart
    retDate.setMinutes(retDate.getMinutes() - (mins % 15));
  }
  retDate && retDate.setSeconds(0);
  return retDate;
}

// validate visit timing duration to be minimum 1h15mins
export function fixVisitEndTime(sDatetime: Date, eDatetime: Date) {
  const diff = calculateDurationHourMinute(sDatetime, eDatetime);
  const mins = diff.hours * 60 + diff.minutes;
  const hours = mins / 60;
  let updatedTime = new Date(eDatetime);

  if (hours < 1.25) { // 75(mins) / 60
    updatedTime = new Date(sDatetime.getTime() + 4500000); // 75(mins) * 60 * 1000
  } else if (hours >= 24) {
    // add 24 hour
    updatedTime = new Date(sDatetime.getTime() + 86400000); // 24 * 60 * 60 * 1000
  } else if (mins % 15 !== 0) {
    // minutes should be 15 minutes apart
    updatedTime.setMinutes(updatedTime.getMinutes() - (mins % 15));
  }
  updatedTime && updatedTime.setSeconds(0);
  return updatedTime;
}

// // Return date in ISO string taking into account local timezone
// export function getLocalDate(d:Date){
//   var tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
//   return toISOLocal(new Date(d.getTime() - tzoffset));
// }

//This generates date string like this '2019-11-30T21:00:00.000Z'
export function toISOLocal(date: Date) {
  let d = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  const z = (n: number) => (n < 10 ? "0" : "") + n;
  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    ".000Z"
  );
}

// export function toISOUtc(date: Date){
//   let minutes = date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes());
//   let hours = date.getHours() >= 10 ? date.getHours() : ('0' + date.getHours());
//   return date.getFullYear() + '-' +
//     ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
//     ('0' + date.getDate()).slice(-2) + 'T' + hours + ":" + minutes + ":00.000+00:00";
// }

// return time entries for time picker
export function getTimeEntries() {
  const mins: any[] = [];
  for (let i = 0; i < 24; i++) {
    mins.push({ mins: i * 60, name: minsToAMPM(i * 60, false) });
    mins.push({ mins: i * 60 + 30, name: minsToAMPM(i * 60 + 30, false) });
  }
  return mins;
}

export function get15MinIntervalTimeEntries() {
  const mins: any[] = [];
  for (let i = 0; i < 96; i++) {
    mins.push({ mins: i * 15, name: minsToAMPM(i * 15, false, false, " ") });
  }
  return mins;
}
export const fifteenMinIntervalTimeEntries = get15MinIntervalTimeEntries();

export function dateDiffHours(date1: Date, date2: Date, abs = true) {
  const diff = date1.getTime() - date2.getTime();
  return (abs ? Math.abs(diff) : diff) / 3.6e6;
}
export function dateDiffDays(date1: Date, date2: Date) {
  const d1 = new Date(date1),
    d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  const diff = d2.getTime() - d1.getTime();
  return isNaN(diff) ? NaN : diff / (1000 * 60 * 60 * 24);
}

// format = "03:15 PM" (assuming input is in correct format)
// returns value in minutes or undefined i.e. '01:30 AM' = 90
export function timeStringToMins(t: string) {
  const regex = "( |:)";
  const components: any = t && t.toLowerCase().split(new RegExp(regex));
  //["03", ":", "15", " ", "pm"]

  if (components && components.length === 5) {
    const h = components[0] % 12;
    const m = components[2] % 60;
    const am = components[4] === "am";
    return (h + (!am ? 12 : 0)) * 60 + (m || 0);
  }
}

// format = "03:15 PM" (assuming input is in correct format)
// returns value date object
export function timeStringToDate(t?: string, existingDate?: Date) {
  if (!t) {
    return UNDEF;
  }
  const regex = "( |:)";
  const components: any = t && t.toLowerCase().split(new RegExp(regex));
  //["03", ":", "15", " ", "pm"]

  if (components && components.length === 5) {
    const h = components[0] % 12;
    const m = components[2] % 60;
    const am = components[4] === "am";

    const d = existingDate || new Date();
    d.setHours(h + (!am ? 12 : 0), m || 0, 0, 0);
    return d;
  }
}

/*// t1,t2 format = "03:15 PM"
export function getTimeDuration(t1?: string, t2?: string) {
  const ret:any = {
    duration: UNDEF,
    nextDay: UNDEF
  };

  const regex = '( |:)';
  const t1Comp:any = t1 && t1.toLowerCase().split(new RegExp(regex));
  const t2Comp:any = t2 && t2.toLowerCase().split(new RegExp(regex));
  //["03", ":", "15", " ", "pm"]

  if(t1Comp && t2Comp && t1Comp.length === 5 && t2Comp.length === 5) {
    const h1 = t1Comp[0] % 12, h2 = t2Comp[0] % 12;
    const m1 = t1Comp[2] % 60, m2 = t2Comp[2] % 60;
    const am1 = t1Comp[4] === 'am', am2 = t2Comp[4] === 'am';

    const d1 = new Date();
    d1.setHours(h1 + (!am1 ? 12 : 0), m1, 0, 0);

    const d2 = new Date();
    d2.setHours(h2 + (!am2 ? 12 : 0), m2, 0, 0);

    if(d2 < d1) ret.nextDay = 'Next day';
    ret.duration = getDurationString(d1, d2, true);
  }
  return ret;
}*/

export function getNext3Months() {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  var chckDates = [];
  let myset = new Set();
  for (let i = 0; i < 90; i++) {
    date.setDate(date.getDate() + 1);
    var dmy = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
    chckDates.push(dmy);
    myset.add(getMonths()[date.getMonth()] + " " + date.getFullYear());
    if (myset.size === 3) {
      break;
    }
  }
  const arr = Array.from(myset);
  return arr;
}
export function getNext3MonthsDates() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  var chckDates = [];
  let myset = new Set();
  for (let i = 0; i < 90; i++) {
    date.setDate(date.getDate() + 1);
    chckDates.push(new Date(date));
    myset.add(getMonths()[date.getMonth()]);
    if (myset.size === 4) {
      break;
    }
  }
  var result = chckDates.reduce((resultArray: any, item, index) => {
    const chunkIndex = Math.floor(index / 4);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  return result;
}
export function calculateAge(birthday: any) {
  if (!birthday) return null;
  let birthDate = birthday;
  if (typeof birthday === "string") {
    birthDate = new Date(birthDate);
  }

  var ageDifMs = Date.now() - birthDate?.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
export function parseQuery(queryString: string) {
  var query: any = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

// checks if a string of 'DD/MM/YYYY' is a valid date
export function isValidDate(dateString: string): boolean {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!dateRegex.test(dateString)) {
    return false; // Invalid format
  }

  const [day, month, year] = dateString.split("/").map(Number);

  if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1000 || year > 3000) {
    return false; // Invalid date
  }

  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day;
}

// convert 'DD/MM/YYYY' string to date object
export function convertDDMMYYYYToDate(dateString: string) {
  const parts = dateString.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-based
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}


