export enum Cycle {
  Regular = "Regular",
  Out_of_Cycle = "Out of Cycle",
}
export enum PaymentTab {
  Cleared = "Cleared",
  Upcoming = "Upcoming",
}
export enum Relationship {
  LOVED_ONE = "loved_one",
  MYSELF = "Myself",
}
export enum Dropdown {
  SELECT = "SELECT",
  OTHER_SELECT = "OTHER_SELECT",
  OTHER_TEXT = "OTHER_TEXT",
  RESET_SELECT="RESET_SELECT",
  RESET_MULTI_SELECT="RESET_MULTI_SELECT",
}
export enum SelfCaStatus {
  Need_Verification="Need Verification",
  Missing='Missing',
  Verified="Verified"
}
export enum IpcaStatus {
  Pending_Payment="Pending Payment",
}
export enum MedicalConditions {
  Mc_Heart="Heart/Blood",
  Vascular="Vascular",
  Neurological="Neurological",
  Psychological="Psychological",
  Skeletal="Skeletal/Muscular",
  Others="Others",
}
export enum Gender {
  Male="Male",
  Female="Female",
}
export enum ServiceType {
  HPC="Home Personal Care & Nursing",
  HOME_THERAPY="Home Therapy",
  HOME_MEDICAL="Home Medical",
  TELE_CONSULTION="Tele-Consultation",
}
export enum GoogleLocationType {
  STREET_NUMBER="street_number",
  ROUTE="route",
  LOCALITY="locality",
  ADMINISTRATIVE_AREA_LEVEL_1="administrative_area_level_1",
  POSTAL_CODE="postal_code",
  COLLOQUIAL_AREA="colloquial_area"
}
export enum ReqFieldLabels {
  relation="relation",
  gender="gender",
  title="title",
  first_name="first name",
  last_name="last name",
  phone_number="phone number",
  age="age",
  DOB="DOB",
  height="height",
  location="location",
  weight="weight",
  roe= "reasons of engagement",
  funding_scheme="funding scheme",
  case_background="case background",
  suddenChange="Sudden change in mobility/ medical conditions",
  Living_Arrangement="Living Arrangement",
  Type_of_Housing="Type of Housing",
  Lift_Accessibility="Lift Accessibility",
  Emotional_Status="Emotional Status",
  Vision="Vision",
  Hearing="Hearing",
  Mobility_Aids='Mobility Aid/s',
  Medication="Medication",
  Allergies='Allergies',
  MedicalEquipment="Medical equipment at home",
  Altertness_Level="Altertness Level",
  Speech="Speech",
  Mobility_Level= "Mobility Level",
  Manual_transfer_steps='Manual Transfer - video/steps for manual transfer',
  Hospitalisation_Summary="Hospitalisation Summary",
  Doctor_memo_photos="Photo(s) of the doctor’s memo",
  Home_services="Home Therapy Services"
}

export const ChargeType:any= {
  visit:"Visit",
  adjustment:"Visit Adjustments",
  claims:"Visit Claims",
  ca:"Care Assessment",
  package:"Package Purchase",
  teleconsult:'Tele-Consulation'
}
