import { createStore, applyMiddleware } from 'redux';
import { reducers } from './reducers/RootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

let createAppStore: any;

if(process.env.NODE_ENV === 'development') {
  // Custom logger options
  const logger = createLogger({
    collapsed: true,
    duration: true,
    timestamp: true,
    logErrors: true,
    diff: true,
  });

  createAppStore = applyMiddleware(thunk, logger);
} else {
  createAppStore = applyMiddleware(thunk);
}

export default function configureStore() {
  return createStore(reducers, composeWithDevTools(createAppStore));
}
